@import "variables";

.dropdown__container {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);

  .dropdown-item {
    color: $color-gray-2;
    font-weight: bold;
    font-size: 16px;
    background-color: $color-white;
    transition: 0.2s background ease-in-out, 0.2s box-shadow ease-in-out,
      0.2s color ease-in-out;
    cursor: pointer;

    &:hover,
    &.active {
      background-color: $color-secondary;
      color: $color-primary;
    }
  }
  &.dropdown__action {
    padding: 0;
    .dropdown-item {
      padding-top: 0.8em;
      padding-bottom: 0.8em;
    }
    .dropdown-divider {
      padding: 0;
      margin: 0;
    }
  }
}
