@import "variables";

.color--blue {
  color: $color-primary;
}

.color--blue__imp {
  color: $color-primary !important;
}

.color--white {
  color: $color-white;
}

.color--black {
  color: $color-black;
}

.bg--color--black {
  background-color: $color-black;
}

.color--basic--black {
  color: $color-basic-black;
}

.color--basic--black--imp {
  color: $color-basic-black !important;
}

.color--red {
  color: $color-red;
}

.color--red__imp {
  color: $color-red !important;
}

.color--light--gray {
  color: $color-light-gray;
}

.color-secondary-gray {
  color: $color-gray-2;
}

.color--yellow {
  color: $color-yellow;
}

.bg--color--blue {
  background-color: $color-primary;
}

.color--disabled {
  color: $color-disabled;
}

.bg--color--disabled {
  background-color: $color-disabled;
}

.color--green {
  color: $color-success-green;
}

.color--green__imp {
  color: $color-success-green !important;
}

.color--green2 {
  color: $color-green-2;
}

.color--green2__imp {
  color: $color-green-2 !important;
}

.bg--color--yellow {
  background-color: $color-yellow;
}

.color--secondary-red {
  color: $color-secondary-red;
}

.color--gray {
  color: $grayColor;
}

.color--gray--2 {
  color: $color-gray-2;
}

.color--gray--2--imp {
  color: $color-gray-2 !important;
}

.on__hover__blue {
  &:hover {
    color: $color-primary !important;
  }
}
