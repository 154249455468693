@import "variables";

.checkbox {
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;

  &.w-100 {
    margin-right: 0;
  }
}

.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  clip: rect(0, 0, 0, 0);

  &:checked ~ .checkbox__data {
    .checkbox__text,
    .checkbox__text--black {
      color: $color-basic-black;
    }

    .checkbox__indicator {
      background-color: $color-primary;
      color: $color-basic-black;

      &:before {
        opacity: 1;
        transform: rotate(45deg);
      }
    }
  }

  &:disabled ~ .checkbox__data {
    opacity: 0.5;
    cursor: auto;

    &:hover {
      background: $color-gray-3;
    }
  }
}

.checkbox__input:focus + .checkbox__data {
  border: 1px dashed $color-primary;
}

.checkbox__data {
  display: flex;
  padding: 10px 12px;
  border-radius: 10px;
  box-sizing: border-box;
  align-items: center;
  transition: 0.3s;
}

.checkbox__indicator {
  position: relative;
  margin-right: 9px;
  width: 22px;
  min-width: 22px;
  height: 22px;
  background: $color-white;
  border: 1px solid $color-gray-2;
  box-sizing: border-box;

  &:before {
    content: "";
    display: block;
    width: 8px;
    height: 11px;
    border: 2px solid $color-white;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 3px;
    left: 6px;
    opacity: 0;
    transform: rotate(-105deg);
    transition: 0.3s;
  }
}

.checkbox__text {
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 1.2;
  margin-bottom: 0px;
}

.inputs__flex {
  display: flex;
  flex-wrap: wrap;
}

.checkbox.large {
  .checkbox__indicator {
    height: 26px;
    min-width: 26px;
    width: 26px;

    &:before {
      width: 8px;
      height: 11px;
      top: 5px;
      left: 8px;
    }
  }
}

.radio__group {
  .checkbox__indicator {
    border-radius: 50%;
  }
}