.mt-10{
  margin-top: 10px;
}

.mb-10{
  margin-bottom: 10px;
}

.mr-12{
  margin-right: 12px;
}

.mb-15{
  margin-bottom: 15px;
}

.mt-15{
  margin-top: 15px;
}

.mb-30{
  margin-bottom: 30px;
}

.mt-30{
  margin-top: 30px;
}

.mt-40{
  margin-top: 40px;
}

.mb-40{
  margin-bottom: 40px;
}

.mt-50{
  margin-top: 50px;
}

.mb-50{
  margin-bottom: 50px;
}

.mt-55{
  margin-top: 55px;
}

.mb-55{
  margin-bottom: 55px;
}
