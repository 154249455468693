@import "variables";

.button {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 100%;
  padding: 8px 20px;
  border: 0 none;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-transition: .2s background ease-in-out, .2s box-shadow ease-in-out, .2s color ease-in-out;
  -o-transition: .2s background ease-in-out, .2s box-shadow ease-in-out, .2s color ease-in-out;
  -moz-transition: .2s background ease-in-out, .2s box-shadow ease-in-out, .2s color ease-in-out;
  transition: .2s background ease-in-out, .2s box-shadow ease-in-out, .2s color ease-in-out;

  &:disabled{
    cursor: initial;
  }
}

.rounded__button{
  border-radius: 50%;
}

.half-rounded__button{
  border-radius: 30px;
}

.icon__left{
  i, em{
    margin-right: 15px;
  }
}

.icon__right{
  i, em{
    margin-left: 15px;
  }
}

.ghost__button{
  color: $color-primary;
  background: transparent;
}

.primary__button{
  background: $color-primary;
  color: $color-white;

  &:hover{
    filter: brightness(90%);
  }

  &:disabled{
    background: $color-disabled;
  }
}

.secondary__button{
  background: $color-secondary;
  color: $color-primary;

  &:hover{
    filter: brightness(90%);
    // background: $color-extra-light-blue;
  }

  &:disabled{
    color: $color-white;
    background: $color-disabled;
  }
}

.success__button {
  background: $color-success-green;
  color: $color-white;

  &:hover{
    background: $color-secondary-green;
  }
}

.white__button{
  background: $color-white;
  color: $color-primary;
  border-left: 1px solid $color-gray;

  &:hover{
    background: $color-secondary;
  }
}

.blue__bordered__button{
  border: 1px solid $color-primary;
  background-color: $color-white;
  color: $color-primary;
}

.large__button{
  height: 56px;
  padding: 16px 20px;
  font-size: 18px;
}

.medium__button{
  height: 48px;
  padding: 12px 20px;
  font-size: 16px;
}

.small__button{
  height: 40px;
  padding: 11px 15px;
  font-size: 12px;
}

.extra__small__button{
  height: 30px;
  padding: 11px 9px;
  font-size: 12px;
  width: 64px;
}

.red__button{
  background: $color-secondary-red;
  color: $color-white;

  &:disabled{
    opacity: 0.5;
  }
}

.button_with__tooltip {
  &::before {
    content: '';
    position: absolute;
    transition: content 0.35s linear;
  }
  &:hover {
    &::before {
      content: attr(data-tooltip);
      background-color: $color-white;
      box-shadow: 0 0 32px rgba(0, 0, 0, 0.15);
      padding: 8px 16px;
      min-width: 185px;
      max-width: 350px;
      right: 55px;
      color: $color-black;
      font-weight: 400;
    }
  }
}

.delete__mage__btn {
  color: $color-red;
  background-color: $color-gray-3;
  &:hover {
    background-color: #ffe8ea;
  }
  &:disabled {
    color: $color-gray-2;
    cursor: not-allowed;
  }
}