@import "variables";

.custom__scroll::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: $color-light-gray;
  border-radius: 10px;
}

.custom__scroll::-webkit-scrollbar
{
  background-color: $color-light-gray;
  width: 10px;
}

.custom__scroll.vertical__Scroll::-webkit-scrollbar{
  height: 10px;
}

.custom__scroll::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: rgb(122,153,217);
}


.custom__scroll__secondary__blue::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
  border-radius: 10px;
}

.custom__scroll__secondary__blue::-webkit-scrollbar {
  width: 10px;
}

.custom__scroll__secondary__blue.vertical__Scroll::-webkit-scrollbar {
  height: 10px;
}

.custom__scroll__secondary__blue::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-secondary;
}


.custom__scroll__gray::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
  border-radius: 10px;
}

.custom__scroll__gray::-webkit-scrollbar {
  width: 10px;
}

.custom__scroll__gray.vertical__Scroll::-webkit-scrollbar {
  height: 10px;
}

.custom__scroll__gray::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-scroll-bar-gray;
}
