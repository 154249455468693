.font-size-12 {
  font-size: 12px;
}

.font-size-12__imp {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-13__imp {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-14__imp {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-16__imp {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-45 {
  font-size: 45px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-40 {
  font-size: 40px;
}
