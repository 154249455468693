@import "variables";

.pagination-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.valzy__pagination {
    .page-item.active .page-link {
        background-color: $color-primary;
        border: none;
        margin: 0 5px;
    }
    .page-link {
        border: none;
        margin: 0 5px;
        color: $color-primary;
    }
}
