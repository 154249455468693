@import "variables";
@import "src/app/scss/components/media";

.page-title-small{
  font-size: 20px;
  color: $color-basic-black;
  margin-bottom: 10px;
}

.page-title{
  font-size: 48px;
  font-weight: bold;

  @include media('<sm'){
    font-size: 35px;
  }
}

.section--title{
  font-size: 42px;
  color: $color-basic-black;
  font-weight: 700;
}

.blue-box{
  background-color: $color-secondary;
  color: $color-primary;
  font-size: 16px;
  padding: 20px;
  width: 100%;
}

.blue-box.icon{
  display: inline-flex;
  align-items: flex-start;
}

.report__page__with__extra__scroll {
  padding-bottom: calc(100vh - 60%);
}

.footer-buttons.left{
  .button{
    border-left: 1px solid $color-gray;

    &:last-child{
      border-right: 1px solid $color-gray;
    }
  }
}

.footer-parent{
  padding-bottom: 58px;
}

.label{
  color: $color-basic-black;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

.checkbox__wrapper{
  position: relative;

  .checkbox__wrap{
    border-left: 1px solid $color-gray;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;

    .checkbox {
      margin-right: 0px;
      margin-bottom: 0px;
      margin-top: 5px;
    }
  }
}

.gray-box{
  background: linear-gradient(261deg, #F7F7F9 50.55%, rgba(247, 247, 249, 0) 95.82%);
}

.ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: inherit;
}

.two__line__ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.overflow-y-hidden{
  overflow-y: hidden;
}

.cursor-initial{
  cursor: initial;
}

.cursor-initial__imp{
  cursor: initial !important;
}

.cursor-pointer{
  cursor: pointer;
}

.blueish-bg{
  width: 110%;
  position: absolute;
  height: 100%;
  transform: translateX(50%);
  left: -60%;
  z-index: -1;

  .shade{
    background: rgba(65, 100, 226, 0.8);
    opacity: 0.2;
    filter: blur(220px);
    transform: rotate(-45deg);
    position: absolute;
    width: 275px;
    height: 514px;
  }

  .shade.upper{
    left: 100px;
    top: -10px;
  }

  .shade.lower{
    left: 325px;
    top: -10px;
  }
}

.error-block{

  .error-input{
    border: 2px solid $color-red;

    &::after{
      content: '';
      background-image: url("../../../assets/images/input_error_icon.svg");
      position: absolute;
      right: 14px;
      top: 50%;
      display: block;
      width: 23px;
      height: 22px;
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
  }

  .error-input-select {
    border: 2px solid $color-red !important;
  }

  .input{
    position: relative;
  }
}

.error-text{
  color: $color-red;
  font-size: 14px;
  line-height: 14px;
  margin-top: 2px;
}


.popover {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
  border: 1px solid $color-primary;
  .arrow {
    display: block;
  }
}

.custom-debug-window {
  z-index: 99999;
  position: fixed;
  width: 50%;
  max-height: 80%;
  height: auto;
  top: 0;
  left: 0;
  background-color: #000000bd;
  border: 1px solid $color-primary;
  box-sizing: border-box;
  color: #ffa500;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.hide-background {
  @include media("<=sm") {
    display: none;
  }
}

.break-text{
  word-break: break-word;
}

.cashflow-warning{
  font-size: 14px;
  font-weight: 500;
  color: $color-red;
}

.lux-toaster-container{
  @include media("<=sm") {
    max-width: 95% !important;
    min-width: auto !important;
    width: 100%;
  }
}
