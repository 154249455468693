@import "variables";
@import "media";

.modal-backdrop {
  background-color: $color-basic-black;
}

.modal-dialog {
  width: 759px;
  // max-height: 567px;
  @include media('<sm'){
    width: auto;
  }
}

.modal-content,
.modal-header,
.modal-footer {
  border-radius: 0px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-basic-black;
  border-bottom: 1px solid $color-gray;
  padding: 15px 20px 15px 38px;

  .modal-title {
    font-weight: bold;
    font-size: 26px;
  }

  .close {
    span {
      content: url("../../../assets/images/modal-close.svg");
    }
    height: 70px;
    width: 68px;
    color: $color-primary;
    border-left: 1px solid $color-gray;
    opacity: 1;
    margin: -1rem -1rem -18px auto;
  }
}

.modal-body {
  padding: 20px 40px 20px 40px;

  .label {
    color: $color-basic-black;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
  }
  .label-with-info{
    margin-bottom: 0;
  }
  input {
    height: 54px;
  }
  input::-webkit-input-placeholder {
    color: #bec1ca;
    font-size: 20px;
  }
  input:-moz-placeholder {
    color: #bec1ca;
    font-size: 20px;
  }
  input:-ms-input-placeholder {
    color: #bec1ca;
    font-size: 20px;
  }
}

.modal-footer {
  padding: 0px;
  border-top: 1px solid $color-gray;
  display: flex;
  justify-content: flex-end;
  height: auto;
}
.large__button{
  height: 62px;
}

.modal-footer > * {
  margin: 0px;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.c-custom-modal-header--wrapper{
  border-bottom: 1px solid $color-gray !important;
}
.c-custom-modal-footer--wrapper{
  border-top: 1px solid $color-gray !important;
}
