@import "variables";

.input {
  width: 100%;
  padding: 8px 10px;
  height: 42px;
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: normal;
  color: $color-basic-black;
  background: $color-white;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 4%);
  border: 2px solid $color-gray;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.input::-webkit-input-placeholder {
  color: $color-light-gray;
}
.input:-moz-placeholder {
  color: $color-light-gray;
}
.input::-moz-placeholder {
  color: $color-light-gray;
}
.input:-ms-input-placeholder {
  color: $color-light-gray;
}
.input::-ms-input-placeholder {
  color: $color-light-gray;
}
.input::placeholder {
  color: $color-light-gray;
}
.input:focus {
  outline: none;
  border: 2px solid $color-basic-black;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
}

.input.input__blue:focus {
  outline: none;
  border: 2px solid $color-primary;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
}

.input.silent-input {
  border: 0px;
  box-shadow: none;
}

.input:disabled {
  background: $color-gray-3;
  color: $color-light-gray;
  opacity: 0.5;
}

.input__wrap--icon.input {
  padding-left: 45px;
}

.input__wrap--icon.right.input {
  padding-right: 45px;
  padding-left: 10px;
}

.input__icon {
  position: absolute;
  width: 45px;
  left: 0px;
  top: 0px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input__icon--large {
  height: 56px;
}
.input__large {
  height: 56px;
  &:disabled{
    color: $color-basic-black;
    opacity: 1;
  }
}
.input__icon.right {
  right: 0px;
  left: auto;
  border-left: 1px solid $color-gray;
}

select.input {
  appearance: none;
  background-image: url("../../../assets/images/arrow-down-new.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
}

.input__error {
  border: 1px solid $color-secondary-red;
}