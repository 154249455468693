@import "../app/scss/components/variables";
html {
    scroll-behavior: smooth;
}

// for index.html -> loading
.wrap-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;

    &.inside-loader{
      position: relative;
      display: flex;
      justify-content: center;
      top: auto;
      left: auto;
      transform: none;

      .spinner{
        margin: 0;
      }
      .spinner.main {
        margin-top: 0;
      }
    }

    .spinner {
        margin: 50px auto;
        width: 50px;
        height: 60px;
        text-align: center;
        font-size: 10px;
    }

    .spinner.main {
        margin-top: 5px;
    }

    .spinner > div {
        margin-right: 2px;
    }

    .spinner > div {
        background-color: #4164e2;
        height: 100%;
        width: 5px;
        display: inline-block;

        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .spinner .rect3 {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }

    .spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    @-webkit-keyframes sk-stretchdelay {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4);
        }

        20% {
            -webkit-transform: scaleY(1);
        }
    }

    @keyframes sk-stretchdelay {
        0%,
        40%,
        100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }

        20% {
            transform: scaleY(1);
            -webkit-transform: scaleY(1);
        }
    }
}

.container {
    max-width: 1440px;
}

.overflow-x {
    overflow-x: auto;
}

.w-7 {
    width: 7%;
}

.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.max-w-45 {
    max-width: 45%;
}

.w-11 {
    width: 11%;
}

.w-24 {
    width: 24%;
}

.w-175-px-imp {
    width: 175px !important;
}

.loading-spinner {
    top: 0px;
    left: 0px;
    height: 100vh;
    display: grid;
    background-color: #0000001f;
    z-index: 1031;
}

.min-height-90px {
    min-height: 90px;
}

.conclusion-loading {
    background-color: #0000001f;

    .displayText {
        text-align: center;
        padding-top: 50%;
        font-weight: bold;
        animation: fade 2s infinite;
    }
    @keyframes fade {
        0%,
        100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
}

.toast-container{
    pointer-events: all !important;
}
.c-toastr--wrapper {
    padding:16px 24px;
    padding-left: 65px;
    box-shadow: 0px 4px 24px 0px #0000001a;
    min-width: 310px;
    position: relative;
    margin-top: 40px;
    .toast-title {
        font-size: 14px;
        font-weight: 500;
    }
    .toast-close-button {
        position: relative;
        width: 20px;
        height: 20px;
        right: 0;
        top: 0;
        &::after {
            content: "";
            background: url("../assets/images/close.png") no-repeat center center;
            position: absolute;
            top: 0;
            right: -5px;
            width: 10px;
            height: 10px;
        }
        span {
            display: none;
        }
    }
    + .c-toastr--wrapper {
        margin-top: 20px;
    }
    &.toast-error {
        background: $color-toastr-error;
        border: 1px solid $color-toastr-errorBorder;
        background-image: url("../assets/images/Error.png");
        background-repeat: no-repeat;
        background-position: 20px 22px;
         .toast-title{
             color: $color-toastr-errorBorder;
         }
    }
    &.toast-warning {
        background: $color-toastr-warn;
        border: 1px solid $color-toastr-warnBorder;
        background-image: url("../assets/images/warn.png");
        background-repeat: no-repeat;
        background-position: 20px 22px;
         .toast-title{
             color: $color-toastr-warnBorder;
         }
    }
    &.toast-success {
        background: $color-toastr-success;
        border: 1px solid $color-toastr-successBorder;
        background-image: url("../assets/images/success.png");
        background-repeat: no-repeat;
        background-position: 20px 22px;
         .toast-title{
             color: $color-toastr-successBorder;
         }
    }
    &.toast-info {
        background: $color-toastr-info;
        border: 1px solid $color-toastr-infoBorder;
        background-image: url("../assets/images/Info.png");
        background-repeat: no-repeat;
        background-position: 20px 22px;
         .toast-title{
             color: $color-toastr-infoBorder;
         }
    }
}
