@import "variables";

.blue-border{
  border: 1px solid $color-primary;
}

.blue-border-2{
  border: 2px solid $color-primary;
}

.blue-border-top-2{
  border-top: 2px solid $color-primary;
}

.blue-border-bottom-2{
  border-bottom: 2px solid $color-primary;
}

.gray-border-right{
  border-right: 1px solid $color-gray;
}

.gray-border-bottom{
  border-bottom: 1px solid $color-gray;
}

.secondary-border-bottom-2{
  border-bottom: 2px solid $color-secondary;
}
