/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "scss/custom";
@import "app/scss/index.scss";

$DEFAULT-PRIMARY: #4164e2;
$DEFAULT-SECONDARY: #ecf0fd;
$DEFAULT-DISABLED: #a0b2f0;

body {
  font-family: "Roboto";
}

:root {
  --primary-color: $DEFAULT-PRIMARY;
  --secondary-color: $DEFAULT-SECONDARY;
  --disabled-color: $DEFAULT-DISABLED;
}
