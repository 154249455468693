@import "variables";

.list-container {
  .list-header {
    border-bottom: 2px solid $color-primary;

    .item {
      font-size: 16px;
      font-weight: bold;
      padding-top: 10px;
      padding-bottom: 15px;
      color: $color-gray-2;
    }
  }

  .list-body {
    .item {
      border: 1px solid transparent;
      border-bottom: 1px solid $color-gray;
      position: relative;
      padding: 10px 0px;
      color: $color-basic-black;
      font-size: 16px;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        border: 1px solid $color-gray;
        box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.05);

        .option__button { 
          opacity: 1;
        }
      }

      .option__button {
        width: 44px;
        height: 44px;
        margin-left: auto;
        padding: 0;
        opacity: 0;
        transition: all 0.5s ease;
      }
    }
  }
}
