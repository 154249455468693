$color-primary: var(--primary-color);
$color-secondary: var(--secondary-color);
$color-disabled: var(--disabled-color);

$color-white: #fff;
$color-black: #000;
$color-basic-black: #1c2133;
$color-dark-black: #1d1b20;
// $color-primary: #4164e2;

$color-dark-blue: #3450b4;
$color-light-blue: #7a93eb;
$color-extra-light-blue: #d9e0f9;

$color-dark-gray: #575f7d;
$color-gray: #dfe1e9;
$color-light-gray: #bec1ca;
$color-gray-2: #818594;
$color-gray-3: #f7f7f9;
$color-gray-4: #B3B6BF;
$grayColor: #818594;

$lightWhiteColor: #f2f3f9;

$color-yellow: #f3be00;
$color-light-yellow: #dfcb1438;

$color-red: #ff5c5c;
$color-light-red: #fae2e2;
$color-secondary-red: #e24141;

$color-green: linear-gradient(195.12deg, #3ec432 10.64%, #27aa1c 87.91%);
$color-green-2: #2AAE1F;

$color-success-green: #61cd7a;
$color-green-light: #f3fff6;
$color-secondary-green: #3ec432;

$color-toastr-error: #ffeee9;
$color-toastr-errorBorder: #ff5c5c;
$color-toastr-info: #ecf0fd;
$color-toastr-infoBorder: #4164e2;
$color-toastr-warn: #fff9dc;
$color-toastr-warnBorder: #f3be00;
$color-toastr-success: #f0fdec;
$color-toastr-successBorder: #27aa1c;
$color-scroll-bar-gray: #d6d6d6;

// Responsive Breakpoint
$breakpoints: (
  "xsm": 361px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1440px,
) !default;
