@import "variables";

.table-side-container {
  border-right: 2px solid $color-primary;

  .header {
    font-size: 16px;
    font-weight: bold;
    color: $color-gray-2;
    display: flex;
    align-items: center;
    height: 50px;
  }

  .header.top {
    height: 75px;
  }
}

.table__body {
  overflow-x: auto;

  .table-column-header{
    font-size: 23px;
    font-weight: bold;
    color: $color-gray-2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 75px;
    padding: 0px 15px;
    border-bottom: 2px solid $color-primary;
  }

  .table-column {
    border-right: 1px solid $color-gray;

    &:first-child {
      border-left: none;
    }

    .table-column-cell {
      border-top: 1px solid $color-gray;
      display: flex;
      align-items: center;
      height: 50px;

      &:first-child {
        border-top: none;
      }

      .value {
        width: 100%;
        color: $color-basic-black;
        font-size: 16px;
        padding: 0px 15px;
      }
    }

    .last-border {
      border-bottom: 1px solid $color-gray;
    }
  }
}

.vertical--table{
  border: 1px solid $color-gray;
  border-bottom: none;

  .vertical-column {
    border-left: 1px solid $color-gray;
  }

  &:last-child {
    border-bottom: 1px solid $color-gray;
  }
}
