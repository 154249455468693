@import "variables";

.progress-container {
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 15px;

  .progress-item {
    height: 5px;

    .progress-bar {
      background-color: inherit;
    }
  }

  .percentage {
    position: absolute;
    bottom: 0px;
    font-size: 14px;
    font-weight: bold;
  }
}

.progress-container.active {
  .progress-item {
    background-color: $color-secondary;

    .progress-bar {
      background-image: linear-gradient(to right, $color-yellow, rgba($color-yellow, 0));
    }
  }

  .percentage {
    color: $color-yellow;
  }
}

.progress-container.completed {
  .progress-item {
    background-color: $color-primary;
  }

  .percentage {
    color: $color-primary;
  }
}

.dashboard-progress-container {
  position: relative;
  padding-bottom: 30px;
  .dashboard-progress {
    height: 3px;
    .progress-bar {
      background-color: inherit;
    }
  }
  &.completed {
    .dashboard-progress {
      background-color: $color-primary;
    }
    .percentage {
      color: $color-primary;
    }
  }
  &.active {
    .dashboard-progress {
      background-color: $color-secondary;

      .progress-bar {
        background-image: linear-gradient(to right, $color-yellow, rgba($color-yellow, 0));
      }
    }
    .percentage {
      color: $color-yellow;
    }
  }

  .percentage{
    position: absolute;
    bottom: 0;
    font-size: 14px;
    font-weight: 700;
  }
}
